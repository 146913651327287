import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Cambios_devoluciones = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, textAlign: 'center' }}> {/* Centrar el contenido */}
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontSize: '2rem',
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 'bold',
            marginBottom: '2rem',
          }}
        >
          Política de cambios y devoluciones
        </Typography>


        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
            Para solicitar cambios o devoluciones, escribir a la casilla de correo electrónico garantia@onloop.cl
            Si el producto comprado no satisface al cliente, éste puede ser cambiado o devuelto hasta 90 días después de la fecha en que este haya sido recibido. Para poder hacer cambio o devolución es necesario que el producto esté sin uso, con todos sus accesorios, embalajes originales y que sea entregado físicamente a la dirección Avenida Central Gonzalo Perez Llona, 1255 comuna de Maipú, durante los días lunes a jueves, en el horario comprendido entre 9 horas y 17 horas.
            Para efectuar cambios o devoluciones deberá presentarse la boleta, guía de despacho, ticket de cambio, u otro comprobante de la compra. Esta garantía no aplica a productos a pedido o confeccionados a la medida. En el caso de productos que sean informados como usados, abiertos, de segunda selección o con alguna deficiencia, que sean comprados habiéndose informado esta situación en el sitio, contando con un precio reducido, no será aplicable el cambio ni devolución.
            En caso de devolución de dinero, la empresa realizará un abono en el medio de pago que haya utilizado o mediante transferencia electrónica a la cuenta del comprador, en un período no superior a 7 días hábiles de haberse aceptado la devolución, cuestión que será informada a través del correo electrónico que se hubiere registrado.
            GARANTÍAS LEGALES
            Los compradores disponen del derecho a retracto, el cual implica que pueden arrepentirse de la realización de compras electrónicas, dentro de los 10 días siguientes desde que recibió el producto o contrató el servicio. 
            En caso que el producto no cuente con las características técnicas informadas, si estuviera dañado o incompleto, podrá ser cambiado de inmediato. Si presentara fallas o defectos dentro de los 3 meses siguientes a la fecha en que éste fue recibido, se puede optar previa restitución a: a) Reparación gratuita; o b) Cambio o la devolución de la cantidad pagada, siempre que el producto no se hubiera deteriorado por un hecho imputable al consumidor. 
            Si el producto cuenta con una garantía del fabricante, se aplicará el plazo de esta garantía, si este plazo fuera mayor. Sin embargo, si el producto es perecible o está naturalmente destinado a ser usado o consumido en un período breve, el plazo de devolución será el impreso en el producto o en su envoltorio o, si nada dice, será en un plazo de 7 días. Todos estos plazos se suspenderán por el tiempo en que el bien esté siendo reparado en ejercicio de la garantía, y hasta que se complete la reparación.
            Se considerará falla o defecto: a) Productos sujetos a normas de seguridad o calidad de cumplimiento obligatorio que no cumplan con las especificaciones; b) Materiales, partes, piezas, elementos, sustancias o ingredientes que constituyan o integren los productos no correspondan a las especificaciones que ostenten o a las menciones del rotulado; c) Productos que por deficiencias de fabricación, elaboración, materiales, partes, piezas, elementos, sustancias, ingredientes, estructura, calidad o condiciones sanitarias, en su caso, no sean aptos para el uso o consumo al que está destinado o al que el proveedor hubiese señalado en su publicidad; d) Si el proveedor y consumidor hubieren convenido que los productos objeto del contrato deban reunir determinadas especificaciones y esto no ocurre; e) Si después de la primera vez de haberse hecho efectiva la garantía y prestado el servicio técnico correspondiente, subsistieren las deficiencias que hagan al bien inapto para el uso o consumo; este derecho subsistirá para el evento de presentarse una deficiencia objeto de servicio técnico, o volviere a presentarse la misma; f) Si la cosa objeto del contrato tiene efectos o vicios ocultos que imposibiliten el uso a que habitualmente se destine; g) Cualquier otro hecho que estime pertinente la empresa, por partidas defectuosas u otras razones.
            Todos los cambios, reparaciones o devoluciones a las que refiere el presente título, se realizarán en conformidad a lo dispuesto en el título anterior, denominado “CAMBIOS Y DEVOLUCIONES”. 
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontFamily: 'Outfit, sans-serif',
            fontWeight: 400,
            textAlign: 'justify',
            textJustify: 'inter-word',
            
            '&:last-of-type': {
              textAlign: 'left',
            },
          }}
        >
            Vigente desde el 25 de Octubre del 2024
        </Typography>
      </Box>
    </Container>
  );
};

export default Cambios_devoluciones;
