import React, { useState } from 'react';
import { TextField, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { validarRut } from '../../Utils/validarRut';

const DatosEntrega = ({
    direccion,
    correo,
    nombre,
    rut,
    telefono,
    facturacion,
    // precioVisible,
    // comunaPrecio,
    handleDireccionChange,
    handleCorreoChange,
    handleNombreChange,
    handleRutChange,
    handleTelefonoChange,
    handleRutValidoChange,
    handleTelefonoValidoChange,
    handleCheckboxChange,
    rutEmpresa,
    setRutEmpresa,
    rutInvalidoEmpresa,
    setRutInvalidoEmpresa,
    rutValidoEmpresa,
    setRutValidoEmpresa,
    razonSocial,
    setRazonSocial,
    giro,
    setGiro,
    direccionEmpresa,
    setDireccionEmpresa,
    envio,
  
}) => {
    const [rutInvalido, setRutInvalido] = useState(false);  // Estado para manejar la validación del RUT
    const [telefonoInvalido, setTelefonoInvalido] = useState(false); // Estado para manejar la validación del teléfono

    // Función para formatear el RUT (número de identificación en Chile)
    const formatRut = (rut) => {
        if (!rut) return '';  // Retorna una cadena vacía si no hay valor
        rut = rut.replace(/[^0-9kK]/g, '');  // Elimina caracteres no numéricos excepto 'K' o 'k'
        rut = rut.replace(/k/g, 'K');  // Convierte 'k' a 'K'
        return rut.length > 1 ? `${rut.slice(0, -1)}-${rut.slice(-1)}` : rut;  // Agrega guion antes del último dígito
    };

    // Controlador de cambio para el campo RUT con formateo y validación
    const handleFormattedRutChange = (event) => {
        const formattedRut = formatRut(event.target.value);  // Aplica el formato al RUT ingresado

        // Actualiza el valor del RUT en el formulario
        handleRutChange({ ...event, target: { ...event.target, value: formattedRut } });  // Actualiza con el valor ingresado

        // Validar el RUT después de actualizar el valor
        if (validarRut(formattedRut)) {
            setRutInvalido(false);  // Si el RUT es válido, elimina el mensaje de error
            handleRutValidoChange(true);  // Notifica que el RUT es válido
        } else {
            setRutInvalido(true);  // Si no es válido, muestra el error
            handleRutValidoChange(false);  // Notifica que el RUT es inválido
        }
    };

    // Función para formatear y validar el RUT de empresa
    const handleFormattedRutEmpresaChange = (event) => {
        const formattedRut = formatRut(event.target.value); // Aplica el formato al RUT ingresado

        // Actualiza el valor del RUT de empresa
        setRutEmpresa(formattedRut);

        // Validar el RUT de empresa después de actualizar el valor
        if (validarRut(formattedRut)) {
            setRutInvalidoEmpresa(false);  // Si el RUT es válido, elimina el mensaje de error
            setRutValidoEmpresa(true);     // Notifica que el RUT es válido
        } else {
            setRutInvalidoEmpresa(true);   // Si no es válido, muestra el error
            setRutValidoEmpresa(false);    // Notifica que el RUT es inválido
        }
    };

    // Controlador de cambio para el campo teléfono con validación
    const handleTelefonoFormattedChange = (event) => {
        const value = event.target.value;

        // Validar que el teléfono tenga exactamente 8 dígitos
        if (/^\d{0,9}$/.test(value)) {
            handleTelefonoChange(event);
            setTelefonoInvalido(value.length !== 8);
            handleTelefonoValidoChange(value.length === 8);  // Notifica que el teléfono es válido si tiene exactamente 8 dígitos
        } else {
            setTelefonoInvalido(true);
            handleTelefonoValidoChange(false);  // Notifica que el teléfono es inválido
        }
    };

    return (
        <Box sx={{ mt: 2, maxWidth: '800px', margin: 'auto' }}>
            <TextField
                id="nombre"
                label="Nombre Completo"
                variant="outlined"
                fullWidth
                value={nombre}
                onChange={handleNombreChange}
                placeholder="Ej: Juan Pérez"
                required
                InputProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            <TextField
                id="rut"
                label="RUT"
                variant="outlined"
                fullWidth
                value={rut}
                onChange={handleFormattedRutChange}
                placeholder="Ej: 12.345.678-9"
                required
                error={rutInvalido}
                helperText={rutInvalido ? "RUT no válido" : ""}
                InputProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography
                    variant="body1"
                    sx={{
                        mr: 1,
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}
                >
                    +569
                </Typography>
                <TextField
                    id="telefono"
                    label="Teléfono"
                    variant="outlined"
                    fullWidth
                    value={telefono}
                    onChange={handleTelefonoFormattedChange}
                    placeholder="12345678"
                    required
                    error={telefonoInvalido}
                    helperText={telefonoInvalido ? "El número debe tener exactamente 8 dígitos" : ""}
                    InputProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }
                    }}
                    InputLabelProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        }
                    }}
                />
            </Box>
            <TextField
                id="correo"
                label="Correo"
                variant="outlined"
                fullWidth
                value={correo}
                onChange={handleCorreoChange}
                placeholder="Ej: ejemplo@correo.com"
                required
                InputProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }
                }}
                sx={{ mb: 2 }}
            />
            {envio !== 'Retiro' && (
                <>
                    <TextField
                        id="direccion"
                        label="Dirección"
                        variant="outlined"
                        fullWidth
                        value={direccion}
                        onChange={handleDireccionChange}
                        placeholder="Ej: Av. Siempre Viva 742"
                        required
                        InputProps={{
                            sx: {
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }
                        }}
                        InputLabelProps={{
                            sx: {
                                fontFamily: 'Outfit, sans-serif',
                                fontWeight: 400,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                            }
                        }}
                        sx={{ mb: 2 }}
                    />
                </>
            )}

            {/* Checkbox para activar/desactivar datos de facturación */}
            <FormControlLabel
                control={
                <Checkbox
                    checked={facturacion} // Vincula el estado al prop
                    onChange={handleCheckboxChange} // Usa la función pasada desde el padre
                    color="primary"
                />
                }
                label={
                <Typography
                    sx={{
                    fontFamily: 'Outfit, sans-serif',
                    fontWeight: 400,
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                }}
                >
                    Agregar datos de facturación
                </Typography>
                }
            />


            {/* Título, visible solo si el checkbox está seleccionado */}
            {facturacion && (
                <Typography
                    variant="h6"
                    sx={{
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 700, // Usamos un peso mayor para diferenciarlo como título
                        textAlign: 'center', // Centramos el título
                        mt: 2, // Margen superior para separar del checkbox
                }}
                >
                    Datos de facturación
                </Typography>
            )}

            {/* Campos de datos de facturación, visibles solo si el checkbox está seleccionado */}
            {facturacion && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
                    <TextField
                        label="Rut de empresa"
                        value={rutEmpresa}
                        onChange={handleFormattedRutEmpresaChange}
                        error={rutInvalidoEmpresa}
                        helperText={rutInvalidoEmpresa ? 'RUT inválido' : ''}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Razón social"
                        value={razonSocial}
                        onChange={(e) => setRazonSocial(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Giro"
                        value={giro}
                        onChange={(e) => setGiro(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
                    <TextField
                        label="Dirección de empresa"
                        value={direccionEmpresa}
                        onChange={(e) => setDireccionEmpresa(e.target.value)}
                        variant="outlined"
                        InputProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                        InputLabelProps={{
                        sx: {
                            fontFamily: 'Outfit, sans-serif',
                            fontWeight: 400,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                        },
                        }}
                    />
            </Box>
            )}


            {/* {precioVisible && comunaPrecio !== null && (
                <Typography
                    sx={{
                        mt: 2,
                        fontFamily: 'Outfit, sans-serif',
                        fontWeight: 400,
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                    }}
                >
                    Precio de envío: ${comunaPrecio}
                </Typography>
            )} */}
        </Box>
    );
};

export default DatosEntrega;
