import React, { useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Pendiente = () => {
    
const navigate = useNavigate();

useEffect(() => {
    const timer = setTimeout(() => {
        navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
}, [navigate]);

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f4f4f4'
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    maxWidth: '400px',
                    backgroundColor: '#fff'
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Pago Pendiente
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                    Su pago está en proceso de verificación.
                </Typography>
                <Typography variant="body1" component="p" sx={{ mt: 1 }}>
                    Recibirá una notificación cuando se confirme el pago.
                </Typography>
                <Typography variant="body2" component="p" sx={{ mt: 3, color: 'gray' }}>
                    Será redirigido a la página de inicio en 5 segundos...
                </Typography>
            </Paper>
        </Box>
    );
};

export default Pendiente;
