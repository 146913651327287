import React from 'react';
import ExpandableCard from '../Utils/ExpandableCard';
import { Typography } from '@mui/material';

// Importa las imágenes locales
import image1 from '../Image/Blog/1.jpeg';
import image2 from '../Image/Blog/2.jpeg';
import image3 from '../Image/Blog/3.jpg';
import image4 from '../Image/Blog/4.jpg';
import image5 from '../Image/Blog/5.jpeg';
// import image6 from '../Image/Blog/6.jpg';
// import image7 from '../Image/Blog/7.jpg';
// import image8 from '../Image/Blog/8.jpg';

const Blog = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 20px',
  };

  const wrapperStyle = {
    paddingTop: '30px',
    paddingBottom: '30px',
  };

  // Texto para las tarjetas
  const text1 = "Elegir sillas cómodas y ergonómicas es crucial para mantener la comodidad y la salud durante largas horas de trabajo. Opta por sillas ergonómicas que ofrezcan altura regulable para personalizar la silla a tu postura y aumentar tu productividad. Los materiales duraderos, como la malla resistente, garantizan una larga vida útil, mientras que las bases de metal aseguran la estabilidad necesaria para un uso prolongado. Encuentra las mejores sillas para oficina y transforma tu espacio de trabajo con calidad y confort.";
  const text2 = "Encuentra el escritorio perfecto para tu hogar u oficina con nuestra amplia gama de escritorios diseñados para satisfacer todas tus necesidades. Desde modelos estándar con cajones que ofrecen un diseño clásico y práctico, ideales para mantener tu espacio de trabajo ordenado, hasta escritorios de altura ajustable que promueven una postura saludable y permiten alternar entre sentado y de pie, perfectos para cualquier home office.  Al elegir el escritorio adecuado, mejorarás tu comodidad y productividad, ya sea que estés trabajando, estudiando o creando. Explora nuestra colección y transforma tu área de trabajo en un lugar cómodo y eficiente. ¡Descubre hoy mismo el escritorio ideal para ti!";
  const text3 = "En la búsqueda de soluciones prácticas para la organización y el aprovechamiento del espacio, los lockers de metal se han convertido en una opción cada vez más popular tanto en entornos domésticos como en oficinas. Estos versátiles muebles de metal no solo aportan una estética moderna y profesional, sino que también ofrecen una serie de ventajas funcionales que pueden transformar la manera en que gestionamos nuestros espacios. Los lockers de metal de onloop, fabricados con metal de alta calidad, aseguran una durabilidad excepcional y una resistencia superior ante el desgaste, la humedad y los golpes.";
  const text4 = "Los juguetes desempeñan un papel esencial en el desarrollo infantil. En particular, los juguetes que permiten a los niños simular profesiones como doctor, veterinario y cocinero no solo proporcionan entretenimiento, sino que también actúan como herramientas educativas clave. Estos juguetes promueven el crecimiento y el aprendizaje de los niños, fomentando habilidades importantes mientras juegan.";
  const text5 = "¡Descubre la diversión de los autos a control remoto! Estos emocionantes vehículos ofrecen una experiencia única que combina velocidad y maniobrabilidad, perfectos para todas las edades. Desde competiciones con amigos hasta crear circuitos en casa, cada carrera con autos a control remoto es una nueva aventura llena de adrenalina. Con diversos colores y diseños, puedes elegir el auto a control remoto que mejor refleje tu personalidad. Los autos a control remoto no son solo juguetes; son una forma divertida de conectarte con familiares y amigos mientras disfrutas de la emoción de las carreras. ¡Es hora de encender tu motor y salir a la pista con tu auto a control remoto!";

  const textStyle = {
    textAlign: 'center', // Esto centra el texto en las tarjetas
    padding: '10px', // Opcional, para dar espacio al texto
  };
  
  return (
    <div style={wrapperStyle}>
      <Typography variant="h4" align="center" gutterBottom>
        Blog
      </Typography>
      <div style={containerStyle}>
        <ExpandableCard 
          title="Sillas Ergonómicas: Comodidad y Durabilidad" 
          imageSrc={image1} 
          text={text1} 
          style={textStyle} // Aplica el estilo aquí
        />
        <ExpandableCard 
          title="Escritorios Modernos: Escritorios que Inspiran" 
          imageSrc={image2} 
          text={text2} 
          style={textStyle} 
        />
        <ExpandableCard 
          title="Lockers de Metal: Estilo y Funcionalidad para Tu Espacio" 
          imageSrc={image3} 
          text={text3} 
          style={textStyle} 
        />
        <ExpandableCard 
          title="Simulando Profesiones: Juguetes Educativos para Niños" 
          imageSrc={image4} 
          text={text4} 
          style={textStyle} 
        />
        <ExpandableCard 
          title="Autos a Control Remoto: Tecnología, Potencia y Entretenimiento" 
          imageSrc={image5} 
          text={text5} 
          style={textStyle} 
        />
      </div>
    </div>
  );  
};

export default Blog;


// import React, { useState, useEffect, useRef } from 'react';
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Card, CardMedia, CardContent, CardActionArea } from '@mui/material';

// // Importa las imágenes locales
// import image1 from '../Image/Blog/1.jpeg';
// import image2 from '../Image/Blog/2.jpeg';
// import image3 from '../Image/Blog/3.jpg';
// import image4 from '../Image/Blog/4.jpg';
// import image5 from '../Image/Blog/5.jpeg';
// // import image6 from '../Image/Blog/6.jpg';
// // import image7 from '../Image/Blog/7.jpg';
// // import image8 from '../Image/Blog/8.jpg';

// const Blog = () => {
//   const [open, setOpen] = useState(false);
//   const [scroll, setScroll] = useState('paper');
//   const [selectedCard, setSelectedCard] = useState({});

//   const handleClickOpen = (scrollType, cardData) => () => {
//     setSelectedCard(cardData);
//     setOpen(true);
//     setScroll(scrollType);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const descriptionElementRef = useRef(null);
//   useEffect(() => {
//     if (open) {
//       const { current: descriptionElement } = descriptionElementRef;
//       if (descriptionElement !== null) {
//         descriptionElement.focus();
//       }
//     }
//   }, [open]);

//   const containerStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     gap: '20px',  // Espacio entre las tarjetas
//     flexWrap: 'wrap',
//     width: '100%',
//     padding: '0 20px',  // Espacio horizontal en los bordes
//   };

//   const wrapperStyle = {
//     paddingTop: '30px',  // Espacio entre el navbar y el contenido
//     paddingBottom: '30px',  // Espacio entre el contenido y el footer
//   };

//   const cardData = [
//     { title: "Título 1", imageSrc: image1, text: text1 },
//     { title: "Título 2", imageSrc: image2, text: text2 },
//     { title: "Título 3", imageSrc: image3, text: text3 },
//     { title: "Título 4", imageSrc: image4, text: text4 },
//     { title: "Título 5", imageSrc: image5, text: text5 },
//     // { title: "Título 6", imageSrc: image6, text: text6 },
//     // { title: "Título 7", imageSrc: image7, text: text7 },
//     // { title: "Título 8", imageSrc: image8, text: text8 }
//   ];

//   return (
//     <div style={wrapperStyle}>
//       <Typography variant="h4" align="center" gutterBottom>
//         Blog
//       </Typography>
//       <div style={containerStyle}>
//         {cardData.map((card, index) => (
//           <Card key={index} sx={{ maxWidth: 345 }}>
//             <CardActionArea onClick={handleClickOpen('paper', card)}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={card.imageSrc}
//                 alt={card.title}
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                   {card.title}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {card.text.substring(0, 100)}... {/* Muestra los primeros 100 caracteres */}
//                 </Typography>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//         ))}
//       </div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         scroll={scroll}
//         aria-labelledby="scroll-dialog-title"
//         aria-describedby="scroll-dialog-description"
//       >
//         <DialogTitle id="scroll-dialog-title">{selectedCard.title}</DialogTitle>
//         <DialogContent dividers={scroll === 'paper'}>
//           <img src={selectedCard.imageSrc} alt={selectedCard.title} style={{ width: '100%', marginBottom: '20px' }} />
//           <DialogContentText
//             id="scroll-dialog-description"
//             ref={descriptionElementRef}
//             tabIndex={-1}
//           >
//             {selectedCard.text}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Close</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

//   const text1 = "Elegir sillas cómodas y ergonómicas es crucial para mantener la comodidad y la salud durante largas horas de trabajo. Opta por sillas ergonómicas que ofrezcan altura regulable para personalizar la silla a tu postura y aumentar tu productividad. Los materiales duraderos, como la malla resistente, garantizan una larga vida útil, mientras que las bases de metal aseguran la estabilidad necesaria para un uso prolongado. Encuentra las mejores sillas para oficina y transforma tu espacio de trabajo con calidad y confort.";
//   const text2 = "Encuentra el escritorio perfecto para tu hogar u oficina con nuestra amplia gama de escritorios diseñados para satisfacer todas tus necesidades. Desde modelos estándar con cajones que ofrecen un diseño clásico y práctico, ideales para mantener tu espacio de trabajo ordenado, hasta escritorios de altura ajustable que promueven una postura saludable y permiten alternar entre sentado y de pie, perfectos para cualquier home office.  Al elegir el escritorio adecuado, mejorarás tu comodidad y productividad, ya sea que estés trabajando, estudiando o creando. Explora nuestra colección y transforma tu área de trabajo en un lugar cómodo y eficiente. ¡Descubre hoy mismo el escritorio ideal para ti!";
//   const text3 = "En la búsqueda de soluciones prácticas para la organización y el aprovechamiento del espacio, los lockers de metal se han convertido en una opción cada vez más popular tanto en entornos domésticos como en oficinas. Estos versátiles muebles de metal no solo aportan una estética moderna y profesional, sino que también ofrecen una serie de ventajas funcionales que pueden transformar la manera en que gestionamos nuestros espacios. Los lockers de metal de onloop, fabricados con metal de alta calidad, aseguran una durabilidad excepcional y una resistencia superior ante el desgaste, la humedad y los golpes.";
//   const text4 = "¡Descubre la diversión de los autos a control remoto! Estos emocionantes vehículos ofrecen una experiencia única que combina velocidad y maniobrabilidad, perfectos para todas las edades. Desde competiciones con amigos hasta crear circuitos en casa, cada carrera con autos a control remoto es una nueva aventura llena de adrenalina. Con diversos colores y diseños, puedes elegir el auto a control remoto que mejor refleje tu personalidad. Los autos a control remoto no son solo juguetes; son una forma divertida de conectarte con familiares y amigos mientras disfrutas de la emoción de las carreras. ¡Es hora de encender tu motor y salir a la pista con tu auto a control remoto!";
//   const text5 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
//   // const text6 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
//   // const text7 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";
//   // const text8 = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.";



// export default Blog;
