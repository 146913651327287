import React from 'react';
import ExpandableCard from '../../Utils/ExpandableCard';

// Importa las imágenes locales
import image1 from '../../Image/Blog/1.jpeg';
import image2 from '../../Image/Blog/2.jpeg';
import image3 from '../../Image/Blog/3.jpg';

const ForoInicio = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    width: '100%',
  };

  const text1 = "Elegir sillas cómodas y ergonómicas es crucial para mantener la comodidad y la salud durante largas horas de trabajo. Opta por sillas ergonómicas que ofrezcan altura regulable para personalizar la silla a tu postura y aumentar tu productividad. Los materiales duraderos, como la malla resistente, garantizan una larga vida útil, mientras que las bases de metal aseguran la estabilidad necesaria para un uso prolongado. Encuentra las mejores sillas para oficina y transforma tu espacio de trabajo con calidad y confort.";
  const text2 = "Encuentra el escritorio perfecto para tu hogar u oficina con nuestra amplia gama de escritorios diseñados para satisfacer todas tus necesidades. Desde modelos estándar con cajones que ofrecen un diseño clásico y práctico, ideales para mantener tu espacio de trabajo ordenado, hasta escritorios de altura ajustable que promueven una postura saludable y permiten alternar entre sentado y de pie, perfectos para cualquier home office.  Al elegir el escritorio adecuado, mejorarás tu comodidad y productividad, ya sea que estés trabajando, estudiando o creando. Explora nuestra colección y transforma tu área de trabajo en un lugar cómodo y eficiente. ¡Descubre hoy mismo el escritorio ideal para ti!";
  const text3 = "En la búsqueda de soluciones prácticas para la organización y el aprovechamiento del espacio, los lockers de metal se han convertido en una opción cada vez más popular tanto en entornos domésticos como en oficinas. Estos versátiles muebles de metal no solo aportan una estética moderna y profesional, sino que también ofrecen una serie de ventajas funcionales que pueden transformar la manera en que gestionamos nuestros espacios. Los lockers de metal de onloop, fabricados con metal de alta calidad, aseguran una durabilidad excepcional y una resistencia superior ante el desgaste, la humedad y los golpes.";

  return (
    <div style={containerStyle}>
      <ExpandableCard
        title="Sillas Ergonómicas: Comodidad y Durabilidad"
        imageSrc={image1} 
        text={text1}
      />
      <ExpandableCard
        title="Escritorios Modernos: Escritorios que Inspiran"
        imageSrc={image2} 
        text={text2}
      />
      <ExpandableCard
        title="Lockers de Metal: Estilo y Funcionalidad para Tu Espacio"
        imageSrc={image3} 
        text={text3}
      />
    </div>
  );
};

export default ForoInicio;
